import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
	
		<svg version="1.1"
			width={height !== 1920 ? height * (1920 / 1920) : width}
			height={width !== 1920 ? width * (1920 / 1920) : height}
			viewBox='0 0 1920 1920'
			fill='none'>
			<g>
				<circle fill="#EF3F6B" cx="960" cy="960" r="952.68" />
				<g>
					<g>
						<path fill="#37214F" d="M319.54,580.08L426.2,383.85c27.75-51.05,93.83-73.64,147.31-50.36l69.33,30.18
				c25.87,11.26,44.97,31.5,53.8,56.99c0.01,0.03,0.02,0.07,0.03,0.1L741,548.83c8.82,25.49,6.29,53.12-7.13,77.82l-19.32,35.55
				c-3.13,5.76-10.56,8.3-16.59,5.67l-1.93-0.84l4.64,13.42c0.98,2.85,0.69,6-0.81,8.76l-72.66,133.67
				c-27.75,51.05-93.83,73.64-147.31,50.36l-69.33-30.18c-25.87-11.26-44.98-31.5-53.8-56.99c-5.65-16.34-27.57-80.85-40.28-118.31
				C303.18,640.07,304.28,608.15,319.54,580.08z"/>
						<path fill="#FFFFFF" d="M584.76,496.84l19.32-35.55c3.31-6.09,0.81-13.31-5.57-16.09l-69.33-30.18
				c-6.38-2.78-14.27-0.08-17.58,6.01L404.95,617.26c-3.31,6.09-0.81,13.31,5.57,16.09l69.33,30.18c6.38,2.78,14.27,0.08,17.58-6.01
				l34.01-62.56l-53.8-23.42l38.65-71.11l128.29,55.85l-72.66,133.67c-24.62,45.3-83.27,65.35-130.72,44.69l-69.33-30.18
				c-47.46-20.66-66.03-74.32-41.41-119.63L437.12,388.6c24.62-45.3,83.27-65.35,130.72-44.69l69.33,30.18
				c47.46,20.66,66.03,74.32,41.41,119.63l-19.32,35.55L584.76,496.84z"/>
						<path fill="#37214F" d="M594.83,578.59l-53.33,98.12c-15.92,29.29-53.84,42.25-84.52,28.89l-69.33-30.18
				c-30.68-13.36-42.69-48.05-26.77-77.34l106.66-196.24c15.92-29.29,53.83-42.25,84.52-28.89l69.33,30.18
				c30.68,13.36,42.7,48.06,26.77,77.35c-1.96,3.6-6.6,5.19-10.37,3.54c-3.77-1.64-5.24-5.89-3.28-9.49
				c12.02-22.11,2.95-48.29-20.21-58.37l-69.33-30.18c-23.16-10.08-51.77-0.3-63.79,21.81L374.53,604.02
				c-12.02,22.11-2.95,48.29,20.21,58.37l69.33,30.18c23.16,10.08,51.77,0.3,63.79-21.81l49.79-91.6l-46.97-20.45
				c-3.77-1.64-5.24-5.89-3.28-9.49s6.6-5.18,10.37-3.54l53.8,23.42C595.31,570.75,596.78,574.99,594.83,578.59z"/>
					</g>
					<g>
						<path fill="#37214F" d="M593.94,916.75c0.98,2.85,3.17,5.15,6.06,6.41l77.26,33.64c4.91,2.14,10.93,0.88,14.69-3.07
				l53.83-56.58l0.7,0.3l34.93,100.92c0.98,2.85,3.17,5.15,6.06,6.41l77.26,33.63c3.41,1.48,7.45,1.36,10.92-0.33
				c3.47-1.69,5.98-4.75,6.78-8.28l91.98-405.83c0.44-1.96,0.34-3.97-0.3-5.83l-44.37-128.18c-0.98-2.85-3.17-5.15-6.06-6.41
				l-56.75-24.71c-4.92-2.14-10.93-0.88-14.69,3.07L552.27,777.22c-3.01,3.16-4.04,7.5-2.7,11.36L593.94,916.75z"/>
						<g>
							<path fill="#FFFFFF" d="M698.76,754.93l68.47,29.81l-18.46,81.45l77.26,33.64l91.98-405.83l-56.75-24.71L561.3,784.57
					l77.26,33.64L698.76,754.93z M797.51,651.13l-12.14,53.57l-27.45-11.95L797.51,651.13z"/>
						</g>
						<path fill="#37214F" d="M624.28,775.79c3.12,2.54,7.98,2.13,10.87-0.9l56.22-59.09l114.83,49.99l-17.24,76.06
				c-0.74,3.25,0.98,6.35,3.97,7.65c0.6,0.26,1.25,0.45,1.94,0.56c4.15,0.63,8.24-2.03,9.12-5.94l73.84-325.79
				c0.72-3.18-0.91-6.31-3.98-7.65c-3.07-1.34-6.83-0.55-9.18,1.92L623.87,765.7C620.98,768.73,621.16,773.25,624.28,775.79z
				 M856.97,541.74l-47.46,209.38L702.21,704.4L856.97,541.74z"/>
					</g>
					<g>
						<path fill="#37214F" d="M1400.85,666.59c-0.99-2.85-3.17-5.15-6.06-6.42l-59.25-25.79c-3.98-1.73-8.77-1.26-12.5,1.23
				l-141.65,94.68l-1.28-48.04c-0.03-1.08-0.22-2.14-0.57-3.15l-44.37-128.18c-0.99-2.85-3.17-5.15-6.06-6.42l-59.25-25.8
				c-2.9-1.26-6.27-1.37-9.38-0.31c-3.11,1.06-5.7,3.21-7.2,5.98L857.29,884.94c-1.5,2.76-1.79,5.92-0.81,8.76l44.37,128.18
				c0.99,2.85,3.17,5.15,6.06,6.42l74.49,32.43c6.03,2.63,13.46,0.09,16.59-5.67l50.56-93.03l33.36,96.38
				c1.17,3.37,4,5.96,7.65,6.98c3.65,1.02,7.69,0.35,10.91-1.8l22.35-14.94l28.47,82.26c0.99,2.85,3.17,5.15,6.06,6.42l74.49,32.43
				c6.03,2.63,13.46,0.09,16.59-5.67l195.97-360.55c1.5-2.76,1.79-5.92,0.81-8.76L1400.85,666.59z"/>
						<polygon fill="#FFFFFF" points="1129.4,778.8 1123.43,554.94 1064.18,529.14 868.21,889.69 942.7,922.12 1044.19,735.4 
				1049.27,926.22 1220.15,812 1118.66,998.73 1193.15,1031.16 1389.12,670.6 1329.87,644.81 			"/>
						<path fill="#37214F" d="M1337.68,684.37c-2.61-2.06-6.48-2.13-9.4-0.18L1096.67,839l-6.89-258.63
				c-0.09-3.26-2.54-5.94-5.96-6.51c-3.42-0.57-6.94,1.11-8.56,4.08L917.95,867.38c-1.96,3.6-0.49,7.85,3.28,9.49
				c3.77,1.64,8.41,0.05,10.37-3.54l143.55-264.11l6.49,243.83c0.07,2.6,1.66,4.89,4.15,5.98c2.49,1.08,5.48,0.79,7.81-0.77
				l218.35-145.94L1168.4,976.42c-1.96,3.6-0.49,7.85,3.28,9.49c3.77,1.64,8.41,0.05,10.37-3.54l157.32-289.44
				C1340.99,689.94,1340.3,686.43,1337.68,684.37z"/>
					</g>
					<g>
						<path fill="#37214F" d="M1281.93,1178.7c0.99,2.85,3.17,5.15,6.06,6.42l241.46,105.12c2.9,1.26,6.27,1.37,9.38,0.31
				c3.11-1.06,5.7-3.21,7.2-5.98l38.65-71.11c1.5-2.76,1.79-5.92,0.81-8.76l-38.63-111.59l33-60.71c1.5-2.76,1.79-5.92,0.81-8.76
				l-28.13-81.28l134.24,58.44c2.9,1.26,6.27,1.37,9.38,0.31c3.11-1.06,5.7-3.21,7.2-5.98l38.65-71.11c1.5-2.76,1.79-5.92,0.81-8.76
				l-44.37-128.18c-0.99-2.85-3.17-5.15-6.06-6.42l-241.46-105.12c-2.9-1.26-6.27-1.37-9.38-0.31c-3.11,1.06-5.7,3.21-7.2,5.98
				l-195.97,360.55c-1.5,2.76-1.79,5.92-0.81,8.76L1281.93,1178.7z"/>
						<g>
							<polygon fill="#FFFFFF" points="1529.4,1080.52 1362.43,1007.83 1402.44,934.21 1485.93,970.56 1524.58,899.45 
					1441.09,863.11 1481.1,789.49 1648.07,862.18 1686.72,791.07 1445.27,685.96 1366.6,830.68 1327.95,901.79 1249.29,1046.51 
					1490.75,1151.63 				"/>
						</g>
						<path fill="#37214F" d="M1626.61,816.93c3.77,1.64,8.41,0.05,10.37-3.54c1.96-3.6,0.49-7.85-3.28-9.49l-166.97-72.69
				c-3.77-1.64-8.41-0.05-10.37,3.54l-157.32,289.44c-1.96,3.6-0.49,7.85,3.28,9.49l166.97,72.69c3.77,1.64,8.41,0.05,10.37-3.54
				c1.96-3.6,0.49-7.85-3.28-9.49l-160.14-69.72l71.58-131.69l76.66,33.37c3.77,1.64,8.41,0.05,10.37-3.54
				c1.96-3.6,0.49-7.85-3.28-9.49l-76.66-33.37l71.58-131.69L1626.61,816.93z"/>
					</g>
					<g>
						<path fill="#37214F" d="M967.47,970.22c-0.99-2.85-3.17-5.15-6.06-6.42l-59.25-25.79c-3.98-1.73-8.77-1.26-12.5,1.23
				L748,1033.93l-1.28-48.04c-0.03-1.08-0.22-2.14-0.57-3.15l-44.37-128.18c-0.99-2.85-3.17-5.15-6.06-6.42l-59.25-25.8
				c-2.9-1.26-6.27-1.37-9.38-0.31c-3.11,1.06-5.7,3.21-7.2,5.98l-195.97,360.55c-1.5,2.76-1.79,5.92-0.81,8.76l44.37,128.18
				c0.99,2.85,3.17,5.15,6.06,6.41l74.49,32.43c6.03,2.63,13.46,0.09,16.59-5.67l50.56-93.03l33.36,96.38
				c1.17,3.37,4,5.96,7.65,6.98c3.65,1.02,7.69,0.35,10.91-1.8l22.35-14.94l28.47,82.26c0.99,2.85,3.17,5.15,6.06,6.42l74.49,32.43
				c6.03,2.63,13.46,0.09,16.59-5.67l195.97-360.55c1.5-2.76,1.79-5.92,0.81-8.76L967.47,970.22z"/>
						<polygon fill="#FFFFFF" points="696.02,1082.43 690.05,858.57 630.8,832.77 434.83,1193.33 509.32,1225.75 
				610.81,1039.03 615.89,1229.85 786.77,1115.63 685.28,1302.36 759.77,1334.79 955.74,974.23 896.49,948.44 			"/>
						<path fill="#37214F" d="M904.3,988c-2.61-2.06-6.48-2.13-9.4-0.18l-231.61,154.8L656.41,884
				c-0.09-3.26-2.54-5.94-5.96-6.51c-3.42-0.57-6.94,1.11-8.56,4.08l-157.32,289.44c-1.96,3.6-0.49,7.85,3.28,9.49
				c3.77,1.64,8.41,0.05,10.37-3.54l143.55-264.11l6.49,243.83c0.07,2.6,1.66,4.89,4.15,5.98c2.49,1.08,5.48,0.79,7.81-0.77
				l218.35-145.94l-143.55,264.11c-1.96,3.6-0.49,7.85,3.28,9.49c3.77,1.64,8.41,0.05,10.37-3.54L906,996.55
				C907.61,993.57,906.92,990.06,904.3,988z"/>
					</g>
					<g>
						<path fill="#37214F" d="M870.55,1501.5c0.99,2.85,3.17,5.15,6.06,6.41l241.46,105.12c6.03,2.63,13.46,0.09,16.59-5.67
				l38.65-71.11c1.5-2.76,1.79-5.92,0.81-8.76l-44.37-128.18c-0.99-2.85-3.17-5.15-6.06-6.42l-63.83-27.79l103.81-190.99
				c1.5-2.76,1.79-5.92,0.81-8.76l-44.37-128.18c-0.99-2.85-3.17-5.15-6.06-6.42l-74.49-32.43c-6.03-2.63-13.46-0.09-16.59,5.67
				l-195.97,360.55c-1.5,2.76-1.79,5.92-0.81,8.76L870.55,1501.5z"/>
						<g>
							<polygon fill="#FFFFFF" points="1118.01,1403.32 951.04,1330.63 1108.37,1041.18 1033.88,1008.76 837.91,1369.31 
					1079.36,1474.43 				"/>
						</g>
						<path fill="#37214F" d="M1064.98,1416.14l-160.14-69.72l153.78-282.93c1.96-3.6,0.49-7.85-3.28-9.49
				c-3.77-1.64-8.41-0.05-10.37,3.54L887.65,1347c-1.96,3.6-0.49,7.85,3.28,9.49l166.97,72.69c3.77,1.64,8.41,0.05,10.37-3.54
				C1070.22,1422.03,1068.75,1417.78,1064.98,1416.14z"/>
					</g>
				</g>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 1920,
	height: 1920,
};

export default Logo;
