import React, { createContext, useState, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import authService from '../auth/AuthorizeService';

const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState({});

    useLayoutEffect(() => {
        const fetchData = async () => {
            const user = await authService.getUser();
            setUserProfile(user);
        };
        fetchData();
    }, []);

    const values = useMemo(
        () => ({
            userProfile,
            setUserProfile
        }),
        [
            userProfile
        ],
    );

    authService.subscribe(async () => {
        const user = await authService.getUser();
        console.log('user context updated.');
        console.log(user);
        setUserProfile(user);
    });

    return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UserContext;
