import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
	dashboardMenu,
	memberPages,
	productPages,
	licensePages,
	invitationPages,
	renewalPages,
	salePages,
	subscriptionPages
} from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import UserContext from '../../contexts/userContext';

import Icon from '../../components/icon/Icon';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import Popovers from '../../components/bootstrap/Popovers';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userProfile } = useContext(UserContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
					<Navigation menu={memberPages} id='member-pages' data-tour='member-pages' />
					<Navigation menu={licensePages} id='license-pages' data-tour='license-pages' />
					<Navigation menu={invitationPages} />
					<Navigation menu={productPages} id='product-pages' data-tour='product-pages' />
					<Navigation menu={renewalPages} />
					<Navigation menu={salePages} id='sale-pages' data-tour='sale-pages' />
					<Navigation menu={subscriptionPages} id='subscription-pages' data-tour='subscription-pages' />
					</div>
				<div className='aside-foot'>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
