import { t } from "i18next";
import UserType from "./services/backend/models/UserType";
import { ApplicationPaths } from "./auth/ApiAuthorizationConstants";

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
		roles: [UserType.Manager, UserType.Agent]
	},
};

export const demoPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: ApplicationPaths.Login,
		icon: 'Login',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};

export const personalPages = {
	personalProfile: {
		id: 'personalProfile',
		path: 'personal/profile',
		text: 'Profile',
		icon: 'AccountBox'
	},
};

export const memberPages = {
	title: {
		id: 'member-page-title',
		text: 'Members',
		icon: 'Extension',
		roles: [UserType.Manager, UserType.Agent]
	},
	agent: {
		id: 'agentPage',
		path: 'member/agents',
		text: 'Agent',
		icon: 'PeopleFill',
		roles: [UserType.Manager, UserType.Agent]
	},
	manager: {
		id: 'managerPage',
		path: 'member/managers',
		text: 'Manager',
		icon: 'Security',
		roles: [UserType.Manager]
	},
};

export const licensePages = {
	title: {
		id: 'license-page-title',
		text: 'Licensing',
		icon: 'Extension',
		roles: [UserType.Manager, UserType.Agent]
	},
	license: {
		id: 'licensePage',
		path: 'licenses',
		text: 'Licenses',
		icon: 'FormatListNumbered',
		roles: [UserType.Manager, UserType.Agent]
	},
};

export const invitationPages = {
	invitation: {
		id: 'invitationPage',
		path: 'invitations',
		text: 'Invitations',
		icon: 'AddLink',
		roles: [UserType.Manager]
	},
};

export const renewalPages = {
	renewal: {
		id: 'renewalPage',
		path: 'renewals',
		text: 'Renewals',
		icon: 'MoreTime',
		roles: [UserType.Manager]
	},
};

export const productPages = {
	product: {
		id: 'productPage',
		path: 'products',
		text: 'Product',
		icon: 'Store',
		roles: [UserType.Manager]
	},
};

export const salePages = {
	sale: {
		id: 'salePage',
		path: 'sales',
		text: 'Sales',
		icon: 'AttachMoney',
		roles: [UserType.Manager, UserType.Agent]
	},
};

export const subscriptionPages = {
	subscription: {
		id: 'subscriptionPage',
		path: 'subscriptions',
		text: 'Subscriptions',
		icon: 'Subscriptions',
		roles: [UserType.Manager]
	},
};