const LANG = {
	EN: {
		text: 'English',
		lng: 'en-US',
		icon: 'CustomUsa',
	},
	TW: {
		text: '繁體中文',
		lng: 'zh-TW',
		icon: 'CustomFrance',
	},
	CN: {
		text: '简体中文',
		lng: 'zh-CN',
		icon: 'CustomTurkey',
	},
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
