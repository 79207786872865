import moment from 'moment';
import { format } from 'date-fns';
import { Views } from 'react-big-calendar';
import { getI18n, useTranslation } from 'react-i18next';
import { zhTW, zhCN, enUS } from "date-fns/esm/locale";
import { SignalWifiStatusbarConnectedNoInternet } from '../components/icon/material-icons';


export const TableLocalStorageTypes = Object.freeze({
    DateFrom: "TableDateFrom",
    DateTo: "TableDateTo",
});

export const getFirstDayOfMonth = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    date.setDate(firstDay.getDate());
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date;
};

export const getYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate());
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date;
};

export const getToday = () => {
    const date = new Date();
    date.setSeconds(59);
    date.setMinutes(59);
    date.setHours(23);
    return date;
};

export const isValidDate = (d) => {
    return d instanceof Date && !Number.isNaN(d);
}

export const formatDate = (date) => {
    const formatedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    return formatedDate;
    // return moment(formatedDate).format("YYYY-MM-DD HH:mm:ss");
};
export const convertUTCDateToLocalDate = (date) => {
    const newDate = new Date(date + 'Z');
    return newDate;
}
export const convertLocalDateToUTCDate = (date) => {
    const newDate = date = date.getUTCFullYear() + '-' +
        ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getUTCDate()).slice(-2) + ' ' +
        ('00' + date.getUTCHours()).slice(-2) + ':' +
        ('00' + date.getUTCMinutes()).slice(-2) + ':' +
        ('00' + date.getUTCSeconds()).slice(-2);
    return newDate;
};

export const convertTicksToTimeSpan = (csharpTicks) => {
    let ticks = csharpTicks / 10000;
    const days = Math.floor(ticks / (1000 * 60 * 60 * 24));
    ticks -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(ticks / (1000 * 60 * 60));
    ticks -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(ticks / (1000 * 60));
    ticks -= minutes * (1000 * 60);

    const seconds = Math.floor(ticks / (1000));
    ticks -= seconds * (1000);

    const i18n = getI18n();

    let timeSpan = "";
    if (seconds) {
        timeSpan = " " + seconds + " " + i18n.t("Second") + timeSpan;
    }
    if (minutes) {
        timeSpan = " " + minutes + " " + i18n.t("Minute") + timeSpan;
    }
    if (hours) {
        timeSpan = " " + hours + " " + i18n.t("Hour") + timeSpan;
    }
    if (days) {
        timeSpan = " " + days + " " + i18n.t("Day") + timeSpan;
    }

    return timeSpan;
};
export const addTicksToDate = (date, csharpTicks) => {
    const ticks = csharpTicks / 10000;
    
    date.setTime(date.getTime() + ticks);

    return date;
};

export const getHourFromTick = (csharpTicks) => {
    let ticks = csharpTicks / 10000;
    const days = Math.floor(ticks / (1000 * 60 * 60 * 24));
    ticks -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(ticks / (1000 * 60 * 60));

    return hours;
};

export const getDayFromTick = (csharpTicks) => {
    const ticks = csharpTicks / 10000;
    const days = Math.floor(ticks / (1000 * 60 * 60 * 24));

    return days;
};

export const getStoredDateFrom = () => {
    let dateFrom = localStorage.getItem(TableLocalStorageTypes.DateFrom);
    if (!dateFrom) {
        dateFrom = getFirstDayOfMonth();
        localStorage.setItem(TableLocalStorageTypes.DateFrom, dateFrom);
    }
    return new Date(dateFrom);
}

export const getStoredDateTo = () => {
    let dateTo = localStorage.getItem(TableLocalStorageTypes.DateTo);
    if (!dateTo) {
        dateTo = getToday();
        localStorage.setItem(TableLocalStorageTypes.DateTo, dateTo);
    }
    return new Date(dateTo);
}

export const storeDateFrom = (dateFrom) => {
    localStorage.setItem(TableLocalStorageTypes.DateFrom, dateFrom);
}

export const storeDateTo = (dateTo) => {
    localStorage.setItem(TableLocalStorageTypes.DateTo, dateTo);
}

export const formatPickerDate = (dateString) => {
    const d = new Date(dateString.replace(' ', 'T'));
    return d;
}


export const getUnitType = (viewMode) => {
    let unitType = null;
    switch (viewMode) {
        case Views.WEEK:
        case Views.WORK_WEEK:
            unitType = Views.WEEK;
            break;
        case Views.MONTH:
        case Views.AGENDA:
            unitType = Views.MONTH;
            break;
        default:
            unitType = Views.DAY;
    }
    return unitType;
};

export const getLabel = (date, viewMode) => {
    if (viewMode === Views.MONTH) return moment(date).format('MMMM YYYY');
    if (viewMode === Views.WEEK)
        return `${moment(date).startOf('week').format('MMM D')} - ${moment(date)
            .endOf('week')
            .format('MMM D')}`;
    if (viewMode === Views.WORK_WEEK)
        return `${moment(date).startOf('week').add(1, 'day').format('MMM D')} - ${moment(date)
            .endOf('week')
            .add(-1, 'day')
            .format('MMM D')}`;
    if (viewMode === Views.AGENDA)
        return `${moment(date).format('L')} - ${moment(date).add(1, 'month').format('L')}`;
    return moment(date).format('dddd, MMM D');
};

export const getTodayButtonLabel = (viewMode) => {
    if (viewMode === Views.MONTH || viewMode === Views.AGENDA) return 'This month';
    if (viewMode === Views.WEEK || viewMode === Views.WORK_WEEK) return 'This week';
    return 'Today';
};

export const getViews = () => {
    return Object.keys(Views).map((k) => Views[k]);
};

export const getDateFNSLocale = () => {
    const i18n = getI18n();
    const lang = i18n.language;
    if (lang.includes('en')) {
        return enUS;
    }
    if (lang.includes('zh-TW')) {
        return zhTW;
    }
    if (lang.includes('zh-CN')) {
        return zhCN;
    }
    return enUS;
}