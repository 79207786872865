/* eslint react/prop-types: off */
import React from 'react'
import { Component } from 'react'
import { Route, Navigate, Outlet } from 'react-router-dom'
import { QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import { demoPages } from '../menu';


export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }

    render() {
        const { ready, authenticated } = this.state;
        const link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${demoPages.login.path}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`
        const { children } = this.props;
        if (!ready) {
            return <div />;
        }

        const { component: Component, ...rest } = this.props;

        if (authenticated) {
            return <Outlet />;
        }
        return <Navigate to={demoPages.login.path} />
    }
}
