import React, { createContext, useState, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import CurrencyType from '../services/backend/models/CurrencyType';

const CurrencyContext = createContext(null);
const currencyLocalKey = "gameml_preferred_currency";

export const CurrencyContextProvider = ({ children }) => {
    const [currency, setCurrency] = useState(
        parseInt(localStorage.getItem(currencyLocalKey), 10) || CurrencyType.USD
    );
    useLayoutEffect(() => {
        localStorage.setItem(currencyLocalKey, currency.toString());
    }, [currency]);
    const values = useMemo(
        () => ({
            currency,
            setCurrency
        }),
        [
            currency
        ],
    );

    return <CurrencyContext.Provider value={values}>{children}</CurrencyContext.Provider>;
};
CurrencyContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CurrencyContext;
