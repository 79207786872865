/* eslint react/jsx-fragments: off */
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contentRoutes from '../../routes/contentRoutes';
import authRoutes from '../../routes/authRoutes';
import AuthorizeRoute from '../../auth/AuthorizeRoute';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	return (
		<React.Fragment>
			<Routes>
				<Route exact path='/' element={<AuthorizeRoute />} >
					{contentRoutes.map((page) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Route key={page.path} {...page} />
					))}
				</Route>
				{authRoutes.map((page) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				))}
				<Route path='*' element={<PAGE_404 />} />
			</Routes>
			<div />
		</React.Fragment>

	);
};

export default ContentRoutes;
