import React from 'react';
import {
	dashboardMenu,
	demoPages,
	personalPages,
	memberPages,
	productPages,
	licensePages,
	invitationPages,
	renewalPages,
	salePages,
	subscriptionPages,
} from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';

const headers = [
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{ path: personalPages.personalProfile.path, element: <DashboardHeader />, exact: true },
	{ path: memberPages.manager.path, element: <DashboardHeader />, exact: true },
	{ path: memberPages.agent.path, element: <DashboardHeader />, exact: true },
	{ path: productPages.product.path, element: <DashboardHeader />, exact: true },
	{ path: licensePages.license.path, element: <DashboardHeader />, exact: true },
	{ path: salePages.sale.path, element: <DashboardHeader />, exact: true },
	{ path: subscriptionPages.subscription.path, element: <DashboardHeader />, exact: true },
	{ path: invitationPages.invitation.path, element: <DashboardHeader />, exact: true },
	{ path: renewalPages.renewal.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: null
	},
];

export default headers;
