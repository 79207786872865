/* eslint no-bitwise: off */
/* eslint one-var: off */
const COLORS = {
	PRIMARY: {
		name: 'primary',
		code: process.env.REACT_APP_PRIMARY_COLOR,
	},
	SECONDARY: {
		name: 'secondary',
		code: process.env.REACT_APP_SECONDARY_COLOR,
	},
	SUCCESS: {
		name: 'success',
		code: process.env.REACT_APP_SUCCESS_COLOR,
	},
	INFO: {
		name: 'info',
		code: process.env.REACT_APP_INFO_COLOR,
	},
	WARNING: {
		name: 'warning',
		code: process.env.REACT_APP_WARNING_COLOR,
	},
	DANGER: {
		name: 'danger',
		code: process.env.REACT_APP_DANGER_COLOR,
	},
	DARK: {
		name: 'dark',
		code: process.env.REACT_APP_DARK_COLOR,
	},
	LIGHT: {
		name: 'light',
		code: process.env.REACT_APP_LIGHT_COLOR,
	},
};

const simpleHash = (str, asString, seed) => {
	let i, l,
		hval = (seed === undefined) ? 0x811c9dc5 : seed;

	for (i = 0, l = str.length; i < l; i++) {
		hval ^= str.charCodeAt(i);
		hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
	}
	if (asString) {
		// Convert to 8 digit hex string
		return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
	}
	return hval >>> 0;
}

export function getColorNameWithIndex(index) {
	/*
	 * The size has been reduced by one so that the LIGHT color does not come out.
	 */
	return COLORS[Object.keys(COLORS)[index % (Object.keys(COLORS).length - 1)]].name;
}

export function getColorNameWithText(text) {
	const newText = simpleHash(text || 'A', true);
	const index = newText.charCodeAt(2);
	return COLORS[Object.keys(COLORS)[index % (Object.keys(COLORS).length - 1)]].name;
}


export default COLORS;
