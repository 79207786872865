import React, { lazy } from 'react';
import { demoPages } from '../menu';
// import Login from '../pages/presentation/auth/Login';
import { Login } from '../auth/Login';
import { Logout } from '../auth/Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from '../auth/ApiAuthorizationConstants';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},

	/**
	 * Auth
	 */
	{
		path: demoPages.login.path,
		element: <Login action={LoginActions.Login} />,
		exact: true,
	},
	{
		path: ApplicationPaths.LoginFailed,
		element: <Login action={LoginActions.LoginFailed} />,
		exact: true,
	},
	{
		path: ApplicationPaths.LoginCallback,
		element: <Login action={LoginActions.LoginCallback} />,
		exact: true,
	},
	{
		path: ApplicationPaths.Profile,
		element: <Login action={LoginActions.Profile} />,
		exact: true,
	},
	{
		path: ApplicationPaths.Register,
		element: <Login action={LoginActions.Register} />,
		exact: true,
	},
	{
		path: ApplicationPaths.LogOut,
		element: <Logout action={LogoutActions.Logout} />,
		exact: true,
	},
	{
		path: ApplicationPaths.LogOutCallback,
		element: <Logout action={LogoutActions.LogoutCallback} />,
		exact: true,
	},
	{
		path: ApplicationPaths.LoggedOut,
		element: <Logout action={LogoutActions.LoggedOut} />,
		exact: true,
	},

	
];
const contents = [...presentation];

export default contents;
