/* eslint react-hooks/exhaustive-deps: off */
import React, { Suspense, useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner'
import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import AuthorizeRoute from '../../auth/AuthorizeRoute';

const _loading = (
	<PageWrapper className='align-middle'>
		<Page className='align-middle'>
			<span className='align-middle'>
				<TailSpin
					height="100"
					width="100"
					color='grey'
					ariaLabel='loading'
				/>
			</span>
		</Page>
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content align-middle'>
			<Suspense fallback={_loading}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
