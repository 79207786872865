import React from 'react';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy2 from './assets/img/wanna/susy/susy2.png';
import Landing3 from './assets/img/wanna/landing3.png';
import Button from './components/bootstrap/Button';

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base) => ({ ...base }),
	maskArea: (base) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base) => ({ ...base }),
	badge: (base) => ({ ...base }),
	popover: (base) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { t } = useTranslation(['translation', 'guide']);
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							{t('guide:Previous')}
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						{t('guide:Next')}
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Finish buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourFinish = () => {
	const { t } = useTranslation(['translation', 'guide']);
	const { currentStep, setCurrentStep, setIsOpen } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>

				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => {
							setIsOpen(false);
							setCurrentStep(0);
						}}>
						{t('guide:Finish')}
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name) => {
	return `[data-tour='${name}']`;
};

const DarkModeTour = () => {
	const { t } = useTranslation(['translation', 'guide']);
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={SusyDarkMode} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:ThemeTitle")}</p>
					<p>{t("guide:ThemeBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LangSwitcherTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy2} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:LanguageTitle")}</p>
					<p>{t("guide:LanguageBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const MemberTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Landing3} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:MemberTitle")}</p>
					<p>{t("guide:MemberBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LicenseTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Landing3} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:LicenseTitle")}</p>
					<p>{t("guide:LicenseBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const ProductTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Landing3} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:ProductTitle")}</p>
					<p>{t("guide:ProductBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const SaleTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Landing3} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:SaleTitle")}</p>
					<p>{t("guide:SaleBody")}</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const ProfileTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Landing3} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>{t("guide:ProfileTitle")}</p>
					<p>{t("guide:ProfileBody")}</p>
				</div>
			</div>
			{/* <TourNavigation /> */}
			<TourFinish />
		</div>
	);
};

/**
 * Tour Steps
 */
const steps = [
	/**
	 * Dark Mode
	 * @step 0
	 */
	{
		selector: getTargetName('dark-mode'),
		content: () => <DarkModeTour />,
	},
	/**
	 * Lang Selector
	 * @step 1
	 */
	{
		selector: getTargetName('lang-selector'),
		content: () => <LangSwitcherTour />,
		highlightedSelectors: [getTargetName('lang-selector-menu')],
		mutationObservables: [getTargetName('lang-selector-menu')],
		resizeObservables: [getTargetName('lang-selector-menu')],
	},
	/**
	 * Member
	 * @step 2
	 */
	{
		selector: getTargetName('member-pages'),
		content: () => <MemberTour />,
	},
	/**
	 * Licenses
	 * @step 3
	 */
	{
		selector: getTargetName('license-pages'),
		content: () => <LicenseTour />,
	},
	/**
	 * Products
	 * @step 4
	 */
	{
		selector: getTargetName('product-pages'),
		content: () => <ProductTour />,
	},
	/**
	 * Sales
	 * @step 5
	 */
	{
		selector: getTargetName('sale-pages'),
		content: () => <SaleTour />,
	},
	/**
	 * Profile
	 * @step 6
	 */
	{
		selector: getTargetName('profile-pages'),
		content: () => <ProfileTour />,
	},
];

export default steps;
