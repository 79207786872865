import moment from 'moment';
import React, { useContext, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { getLangWithKey } from '../../../lang';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import ThemeContext from '../../../contexts/themeContext';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Spinner from '../../../components/bootstrap/Spinner';

const DashboardHeader = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();
	const { t } = useTranslation(['translation', 'menu']);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng)
			.then(() => {
				moment.locale(lng);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
						<span>{`${t('Language changed to')} ${getLangWithKey(lng)?.text}`}</span>
					</span>,
					t('The language of the website has been updated'),
				);
			},
			);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const { setIsOpen } = useTour();

	return (
		<Header>
			<HeaderLeft>
				{/* <Popovers
					title='DashboardHeader.js'
					desc={<code>src/pages/common/Headers/DashboardHeader.js</code>}>
					HeaderLeft
				</Popovers>
				<code>DashboardHeader.js</code> */}
			</HeaderLeft>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					{/* Tour Modal */}
					{localStorage.getItem('tourModalStarted') === 'shown' && (
						<div className='col-auto position-relative'>
							<Popovers trigger='hover' desc={t('Page Guide')}>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon='Tour'
									onClick={() => setIsOpen(true)}
									aria-label={t('Page Guide')}
								/>
							</Popovers>
						</div>
					)}

					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc={darkModeStatus ? t('Light Theme') : t('Dark Theme')}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>
					{/* Lang Selector */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language)?.icon === 'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={getLangWithKey(i18n.language)?.icon}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>

					{/*	Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc={t('Fullscreen')}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label={t('Fullscreen')}
							/>
						</Popovers>
					</div>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
